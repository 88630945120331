import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="dynamic-select"
export default class extends Controller {
  static targets = ["parent", "child"]

  connect() {
    this.childTarget.clone = this.childTarget.cloneNode(true)

    this.updateChild()
  }

  change() {
    this.updateChild()
  }

  updateChild() {
    const selectedGroup = this.parentTarget.options[this.parentTarget.selectedIndex].text;
    const groupOptions = this.childTarget.clone.querySelectorAll("optgroup[label='" + selectedGroup + "']")
    this.childTarget.innerHTML = Array.from(groupOptions).map(optgroup => optgroup.innerHTML)
  }
}
